globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"LfegfsD2jTYYQJjS5n01P"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"
import { APP_ENV, IS_DEV, IS_PROD } from "env-vars"

Sentry.init({
  dsn: "https://df2456e15f46dcaeb7754a7d49ae72c7@o4506110797021184.ingest.us.sentry.io/4506145785708544",
  environment: APP_ENV,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: IS_PROD ? 0.3 : 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  integrations: [Sentry.browserProfilingIntegration(), Sentry.replayIntegration()],

  replaysSessionSampleRate: IS_PROD ? 0.3 : 0.5,
  replaysOnErrorSampleRate: 0.7, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  // Disabled in development by default
  enabled: !IS_DEV,
})
